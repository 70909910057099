.nav1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 10px 0;
  transition: backdrop-filter 0.3s ease;
  z-index: 50;
  margin: 15px 7px;
  font-family: "Cyber Chunk Font", "Silkscreen", sans-serif;
  font-weight: 400;
  font-style: normal;
  position: fixed;
  width: 99vw;
}
.hgt{
  height: 80px;
}
.logo {
  height: 60px;
  width: 60px;
  margin: 0 20px;
  scale: 2;
}

.nav1 a,.down {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  padding: 15px 0;
  margin: 0 5px;
  border-radius: 25px;
  font-size: 20px;
  position: relative;
}

.nav1 .activeLink{
  color:#fbff00
}

.nav1 a::after,.down::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: #fbff00;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav1 a:hover,.down:hover {
  color: #fbff00;
}

.nav1 a:hover::after,.down:hover::after {
  transform: scaleX(1);
}


@media (max-width: 1240px) {
  .nav1 a,.down {
    font-size: 16px;
  }

  .logo {
    height: 50px;
    width: 50px;
  }
}

@media (max-width: 950px) {
  .nav1 {
    /* display: none; */
    position: absolute;
    display: none;
  }
}
.activeLink{
  color:#fbff00;
}
.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 99;
  position: fixed;
  height: 100vh;
  top: 0;
  right: -70vw;
  padding: 60px 0 60px 0;
  width: 0vw;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  font-size: x-large;
}

.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 99;
  position: fixed;
  height: 100vh;
  top: 0;
  padding: 60px 0 60px 0;
  right: 0;
  width: 70vw;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  font-size: x-large;
  transition: right 0.5s ease-in;
}
.miniNav {
  position: fixed;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  height: 75px;
  transition: backdrop-filter 0.3s ease;
  z-index: 1;
  font-family: "Cyber Chunk Font", "Silkscreen", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding-right: 15px;
}

.mini {
  height: 0px;
}

.img {
  height: 60px;
  width: 60px;
  margin-top: 10px;
}

.hamburger {
  font-size: 40px;
  color: white;
  cursor: pointer;
  margin-top: 10px;
}



.hamburg {
  font-size: 40px;
  color: white;
  cursor: pointer;
  position: fixed;
  right: 20px;
  top: 10px;
}

.active a:hover,.down:hover {
  color: #fbff00;
}

.active a:hover::after,.down:hover::after {
  transform: scaleX(1);
}

@media (min-width: 950px) {
  .mini {
    display: none;
  }

  .miniNav {
    display: none;
  }
 
}