/* Navbar styling */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: black;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
  }
  
  .navbar ul {
    display: flex;
    list-style-type: none;
  }
  
  .checkbox-label {
    margin-left: 3px;
  }
  
  .navbar ul li {
    padding: 10px;
    color: white;
  }
  
  /* Hamburger menu for mobile */
  .hamburger {
    display: none;
  }
  
  @media (max-width: 768px) {
    .navbar ul {
      display: none;
    }
  
    .hamburger {
      display: block;
      cursor: pointer;
    }
  
    .navbar.active ul {
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: black;
      top: 50px;
      width: 100%;
      left: 0;
    }
  }

  .main-container{
    display: flex;
    height: 89vh;
    flex-wrap: wrap;
    padding: 60px;
    justify-content: center;
    align-items: center;
    overflow: scroll;
  }

  .payment-info {
    background-color: rgba(0, 0, 0, 0.75);
    width: 90vw;
    max-width: 600px;
    min-height: 300px;
    font-size: 1.8rem;
    color: #fbff00;
    text-align: center;
    font-family: 'Cyber Chunk Font';
    border-radius: 30px;
    padding: 30px 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  }
  .upi{
    font-size: 1rem;
  }
  
  .payment-info h2 {
    font-size: 2.2rem;
    margin-bottom: 15px;
    color: white;
  }
  
  .bank-account {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
  
  /* Form container */
  .form-container {
    width: 70%;
    max-width: 600px; /* Limit max width for larger screens */
    margin: 0 auto;
    overflow: hidden;
    font-size: 18px;
    color: #fff;
    font-family: 'Cyber Chunk Font';
    border-radius: 30px;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .shadow-region {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.68);
    padding: 20px;
    border-radius: 15px;
  }
  
  .form-heading {
    font-size: 2rem;
    color: #fbff00;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-content {
    margin-top: 20px;
    /* font-family: "Silkscreen", sans-serif; */
    color: #fbff00;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    font-size: 1.2rem;
    border-radius: 10px;
    border: 2px solid #fbff00;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fbff00;
    box-sizing: border-box;
  }
  
  .input-field::placeholder {
    color: #fbff00;
    opacity: 70%;
  }
  
  .submit-button {
    padding: 15px 30px;
    font-size: 1.2rem;
    background-color: #fbff00;
    color: black;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .submit-button:hover {
    background-color: #ff00f7;
    color: white;
  }
  
  /* Delete Member Button */
  .member-row {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }
  
  .delete-button {
    background-color: red;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
  }
  
  .delete-button:hover {
    background-color: darkred;
  }
  
  /* Responsive form */
  @media (max-width: 768px) {
    .form-container {
      width: 90%; /* Take up more width on smaller screens */
      font-size: 16px;
      padding: 15px;
    }
  
    .form-heading {
      font-size: 1.8rem;
    }
  
    .input-field {
      padding: 10px;
      font-size: 1rem;
    }
  
    .submit-button {
      font-size: 1rem;
      padding: 10px 20px;
    }
  
    .member-row {
      flex-direction: column;
      gap: 10px;
    }
  
    .delete-button {
      width: 100%;
      padding: 10px;
    }
  }
  
  /* Additional media query for even smaller screens */
  @media (max-width: 480px) {
    .form-container {
      width: 95%; /* Expand to almost full width */
      font-size: 14px;
    }
  
    .form-heading {
      font-size: 1.5rem;
    }
  
    .input-field {
      padding: 8px;
      font-size: 0.9rem;
    }
  
    .submit-button {
      font-size: 0.9rem;
      padding: 8px 15px;
    }
  
    .delete-button {
      font-size: 0.8rem;
    }
  }
  