.events {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 115px);
    overflow: auto;
    text-align: left;
    font-size: 25px;
    color: #fff;
    font-family: 'Cyber Chunk Font';
}

.shadow-region {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
}

.shadow-region-pink {
    background-color: rgba(204, 107, 143, 0.6);
    width: 80vw;
    height: calc(80vh - 50px);
    margin: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 40px;
}

.image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin-left: 10px;
    margin-right: 10px;
    height: 70vh;
}

.path {
    font-size: 2.3vw;
    font-family: 'Cyber Chunk Font';
    margin: 5px auto;
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.underline:hover {
    text-decoration: underline;
    cursor: pointer;
}
.event-heading {
    font-size: 3rem;
    color: #fbff00;
    font-family: 'Cyber Chunk Font';
    margin: 5px;
}

.event-content {
    margin-top: 50px;
    overflow: auto; 
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between; 
    gap: 20px; 
    color: #f5f852;
    font-family: "Silkscreen", sans-serif;
  
   
}


.event-content > div:last-child {
    flex: 1;
    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.glow-border-blue {
    width: 100%;
    height: 3px;
    background-color: white;
    border: 2px solid rgb(84, 211, 253);
    box-shadow: 0 0 15px 2px rgba(84, 211, 253, 0.75);
}

.glow-border-pink {
    width: 100%;
    height: 3px;
    background-color: white;
    border: 2px solid rgb(192, 35, 207);
    box-shadow: 0 0 15px 2px rgba(192, 35, 207, 0.75);
}

.navbar {
    width: 100%;
    height: 50px;
    z-index: 10;
    background-color: black;
}

.event-sub-heading {
    font-size: 1.5rem;
    color: #fbff00;
    margin: 10px 15px;
    /* position: relative;
    top: 20px; */
    padding: 5px;
    /* font-family: "Silkscreen", sans-serif; */
    border-bottom: solid #fbff00;
    z-index: 1;
    text-shadow: 0.3cm #fbff00;
}

.description {
    font-size: 1.5rem;
    margin-top: 10px;
    padding: 10px;
    width: 40vw;
}

.event-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: scroll;
    height: min(70vh, 550px);
}

.button {
    padding: 12px 15px;
    margin: 10px auto 2px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    cursor: pointer;
    color: black;
    background-color: #cc6b8f;
    gap: 3px;
}
.icon {
    width: min-content;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    >svg {
        color: black;
    }
}
.tickIcon {
    width: min-content;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    background-color: #28a745;
    >svg {
        color: white;
    }
}
.isNotActive {
    color: #fbff00;
    opacity: 70%;
    text-shadow: 0;
    border: 0;
}

.event-content h1 {
    font-size: 2rem;
}

.eventInfo {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px;
    font-size: 1.3rem;
    align-items: center;
}

.event-content {
    margin-top: 100px;
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    color: #f5f852;
    font-family: "Silkscreen", sans-serif;
    width: 100%;
}

/* Style for larger screens */
.event-content > div:first-child {
    flex: 1;
    padding: 20px;
}

.event-content > div:last-child {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.submit-button {
    padding: 15px 30px;
    font-size: 1.2rem;
    background-color: #fbff00;
    color: black;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 5px;
  }

/* Responsive styling for mobile devices */
@media (max-width: 768px) {
    .event-heading {
        font-size: 2.2rem; /* Adjust heading size for tablets */
        text-align: center;
        margin-top: 20px;
    }

    .event-sub-heading {
        font-size: 1.3rem; /* Adjust sub-heading size */
        margin: 10px 0;
        text-align: center; /* Center text on mobile */
    }

    .event-content {
        flex-direction: column; /* Stack items vertically */
        gap: 15px; /* Smaller gap between items */
        align-items: center; /* Center items */
        /* Reduce padding */
        margin-top: 40px;
    }

    /* .event-content > div {
     
 
    } */

    .infoCont {
        text-align: center;
        
    }

    .glow-border-blue, .glow-border-pink {
        height: 2px;
        box-shadow: 0 0 10px 1px rgba(84, 211, 253, 0.75); /* Reduced shadow intensity for mobile */
    }

    .navbar {
        height: 45px;
    }
}

/* Styling for small mobile devices */
@media (max-width: 480px) {
    .event-heading {
        font-size: 1.8rem; /* Further reduce heading size for small screens */
        margin-top: 10px;
    }

    .event-sub-heading {
        font-size: 1.1rem; /* Smaller sub-heading */
        text-align: center;
        margin: 5px 0;
    }

    .event-content {
        flex-direction: column; /* Stack items */
        align-items: center;
        
        margin-top: 20px;
         /* Smaller gap */
    }

    .event-content > div {
        max-width: 100%;
        width: 100%;
       
    }

    .eventInfo {
        font-size: 1rem;
        
    }

    .navbar {
        height: 40px;
    }
}
