.eventCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    /* max-width: 500px; */
    margin: auto;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease-in-out;
    padding: 5px 20px;
    /* background: rgba(204, 107, 143, 0.6); */
    background: rgba(255,255,255,0.2);
    border-radius: 10px;
    backdrop-filter: blur(8px); /* Adjust the blur amount as needed */
    text-align: center;
    color: #fff; /* Text color inside the container */
}

.eventCardGlossContainer {
    position: absolute;
    top: 0;
    left: 0%;
    width: 0%;
    height: 100%;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0)
    );
    pointer-events: none;
    transition: transform 2s ease, box-shadow 0.5s ease-in-out;
}

.eventCardContainer:hover {
    cursor: pointer;
    box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.2);
}

.eventCardContainer:hover .eventCardGlossContainer {
    left: 0%;
    width: 100%;
    background-color: none;
}

.posterContainer {
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    border-radius: 10px 10px 0px 0px;
}

.posterContainer img {
    width: 300px;
    height: 300px;
    border-radius: 10px 10px 0px 0px;
}

.tags {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid #fff; */
    border-radius: 10px 0 10px 0;
    flex-wrap: wrap;
    width: 100%;
    /* background: rgba(255, 255, 255, 0.5); */
}

.tagList {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 800;
}

.eventData {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.eventDetailContainer > * {
    padding: min(8px, 3vmin);
    font-size: min(9vmin, 18px);
}

.eventName {
    font-size: min(10vmin, 25px);
    text-transform: uppercase;
    /* font-weight: bold; */
    white-space: nowrap;
    font-family: "Cyber Chunk Font", "Silkscreen", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
}

.eventOverview {
    display: flex;
    justify-content: flex-start;
}

.seperator {
    height: min(2vmin, 5px);
    width: min(2vmin, 5px);
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    align-self: center;
    margin: auto min(2vmin, 8px);
}

.eventDate {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}