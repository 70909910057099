
@import url('https://fonts.googleapis.com/css2?family=Cyber+Chunk+Font:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap');
body {
  	margin: 0; line-height: normal;
}

/* Hides scrollbar for the entire page */
html, body {
	overflow: auto;
  scrollbar-width: none !important;
  }
  
  ::-webkit-scrollbar {
	display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
  }
  
@font-face {
    font-family: 'Cyber Chunk Font';
    src: url('./components/NavBar/CyberChunkFont.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
body {
  margin: 0; line-height: normal;
	font-family: "Cyber Chunk Font","Silkscreen",sans-serif;
}
video::-webkit-media-controls {
  display: none;
}

/* Could Use thise as well for Individual Controls */
/* video::-webkit-media-controls-play-button {}

video::-webkit-media-controls-volume-slider {}

video::-webkit-media-controls-mute-button {}

video::-webkit-media-controls-timeline {}

video::-webkit-media-controls-current-time-display {} */