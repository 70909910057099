.events {
    position: fixed;
    width: 100%;
    height: calc(100vh - 70px);
    overflow: hidden;
    text-align: left;
    font-size: 25px;
    color: #fff;
    font-family: 'Cyber Chunk Font';
}

.shadow-region {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
}

.event-heading {
    font-size: 3rem;
    color: #fbff00;
    font-family: 'Cyber Chunk Font';
    margin: 5px;
}
.event-con{
    width: 99vw;
    height: 60vh;
    overflow: scroll;
    margin-top: 60px;
    padding: 10px;
}
.event-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 80px;
    
}

.glow-border-blue {
    width: 100%;
    height: 3px;
    background-color: white;
    border: 2px solid rgb(84, 211, 253);
    box-shadow: 0 0 15px 2px rgba(84, 211, 253, 0.75);
}

.glow-border-pink {
    width: 100%;
    height: 3px;
    background-color: white;
    border: 2px solid rgb(192, 35, 207);
    box-shadow: 0 0 15px 2px rgba(192, 35, 207, 0.75);
}
.event-sub-heading {
    font-size: 1.8rem;
    color: #fbff00;
    margin: 15px 20px;
    position: relative;
    top: 20px;
    padding: 5px;
    font-family: "Silkscreen", sans-serif;
    border-bottom: solid #fbff00;
    z-index: 1;
    text-shadow: 0.3cm #fbff00;
}

.isNotActive {
    color: #fbff00;
    opacity: 70%;
    text-shadow: 0;
    border: 0;
}

/* --- Mobile Responsiveness --- */
@media (max-width: 1024px) { /* Tablets and larger mobiles */
    .event-heading {
        font-size: 2.7rem; /* Slightly reduce heading size */
    }

    .event-sub-heading {
        font-size: 1.3rem; /* Reduce sub-heading size */
    }
}

@media (max-width: 768px) { /* For mid-size mobile devices */
    .event-heading {
        font-size: 2.5rem; /* Further reduce heading size */
    }

    .event-sub-heading {
        font-size: 1.2rem; /* Further reduce sub-heading size */
        margin: 10px 15px;
        padding: 3px;
    }
}

@media (max-width: 480px) { /* For small mobile devices */
    .event-heading {
        font-size: 2rem; /* Reduce heading size for smaller mobile screens */
    }

    .event-sub-heading {
        font-size: 1rem; /* Smaller sub-heading for mobile screens */
    }
}
