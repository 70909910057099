.video-background {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    height: 100vh;
    min-height: 100vh;
    z-index: -2;
    overflow: hidden;
}

.video-background video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent; /* Transparent overlay */
    z-index: -1; 
    pointer-events: none;
    /* Make sure it's above the video but below other content */
}
