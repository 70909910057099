/* Main Container */
.mainContainer {
  position: relative;
  transition: all 0.3s ease;
  min-height: 100vh;
  width: 100%;
  overflow: hidden; /* Ensure no overflow on mobile */
}

.filtersHidden {
  padding-left: 60px;
}

/* SVG Left Border */
.svgLeftBorder {
  position: fixed;
  top: 40px;
  left: 0;
  height: 100vh;
  z-index: 2;
  width: 131px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.svgLeftBorder:hover {
  transform: scale(1.02);
}

.svgLeftBorder svg {
  height: 100%;
  width: auto;
}

/* Toggle Button for Hidden Filters */
.toggleButton {
  position: fixed;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(84, 211, 253, 0.8);
  transition: all 0.3s ease;
}

.toggleButton:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 0 15px rgba(84, 211, 253, 0.9);
}

/* Filters Section */
.filtersSection {
  position: fixed;
  top: 200px;
  left: 60px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(128, 0, 128, 0.8); /* Purple glow */
  width: 250px;
  height: auto;
  transition: all 0.3s ease;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.filter-heading {
  color: #fbff00;
  text-align: center;
  font-size: 1.5rem;
  /* margin-bottom: 20px; */
  font-weight: bold;
  text-shadow: 0 0 10px rgba(251, 255, 0, 0.5);
}

.main-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

/* Competitions Section */
.competitionsSection {
  margin-left: 340px;
  padding-top: 100px;
  transition: margin-left 0.3s ease;
  text-align: left;
  position: relative;
  padding-right: 30px;
}

.competitionsSection.fullWidth {
  margin-left: 100px;
}

/* Center the Competitions heading */
.centered-heading {
  font-size: 3rem;
  color: #fbff00;
  text-align: center;
  position: relative;
  top: 20px;
  width: 100%;
  z-index: 3;
  margin-bottom: 20px; /* Default space for larger screens */
  text-shadow: 0 0 15px rgba(251, 255, 0, 0.5);
}

/* Events Section */
.eventsSection {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(84, 211, 253, 0.8);
}

.event-nav {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
  padding: 10px 0;
  border-bottom: 2px solid rgba(84, 211, 253, 0.3);
}

.event-sub-heading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fbff00;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
}

.event-sub-heading::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #fbff00;
  transition: width 0.3s ease;
}

.event-sub-heading:hover::after {
  width: 100%;
}

.isNotActive {
  color: #888888;
  opacity: 0.6;
}

/* Event Content Grid */
.event-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 80px; /* Default gap */
  padding: 20px 0;
  overflow: hidden; /* Prevent content from escaping the grid */
}

/* Fix for mobile cards cutting off */
.event-content .transparent-card {
  min-width: 100%; /* Ensure card takes full width */
}

/* Ensuring images inside cards scale correctly */
.transparent-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.competitionsSection.fullWidth .event-content {
  gap: 120px; /* Increased gap when filters are hidden */
}

/* No Events Message */
.no-events {
  color: white;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 20px;
  grid-column: 1 / -1;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  border: 1px solid rgba(84, 211, 253, 0.3);
}

/* Animations */
@keyframes pulseGlow {
  0% {
    box-shadow: 0 0 10px rgba(84, 211, 253, 0.8);
  }
  50% {
    box-shadow: 0 0 20px rgba(84, 211, 253, 0.9);
  }
  100% {
    box-shadow: 0 0 10px rgba(84, 211, 253, 0.8);
  }
}

.toggleButton {
  animation: pulseGlow 2s infinite;
}

/* Responsive Design */
@media (max-width: 768px) {
  .mainContainer {
    padding-left: 0;
  }

  /* Hide the SVG left border on mobile */
  .svgLeftBorder {
    display: none;
  }

  .competitionsSection {
    margin-left: 0;
    padding-right: 0; /* Remove right padding for full width */
    width: 100%; /* Ensure full width */
  }

  .competitionsSection.fullWidth {
    margin-left: 0;
    width: 100%; /* Ensure full width */
  }

  /* Display filters under the competition heading */
  .filtersSection {
    position: static;
    width: 100%;
    top: auto;
    left: auto;
    padding: 10px;
    margin-top: 40px; /* Increase space between competition heading and filters heading */
    margin-bottom: 5px; /* Reduce space between filters and content */
    box-shadow: 0 0 20px rgba(128, 0, 128, 0.8); /* Purple glow */
    background-color: rgba(0, 0, 0, 0.6); /* Add background color for filters section on mobile */
    border-radius: 8px;
  }

  .filters {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .filter-heading {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }

  .event-content {
    grid-template-columns: 1fr;
    gap: 30px;
    padding-right: 0; /* Remove right padding */
  }

  .event-nav {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .toggleButton {
    left: 15px;
  }

  .centered-heading {
    font-size: 2rem;
    margin-bottom: 40px; /* Increase space between competition heading and filters heading */
  }
}

@media (max-width: 480px) {
  .event-content {
    grid-template-columns: 1fr;
    gap: 20px;
    padding-right: 0; /* Remove right padding for full width */
  }

  .centered-heading {
    font-size: 1.8rem;
    margin-bottom: 40px;
  }

  .event-sub-heading {
    font-size: 1.2rem;
    padding: 6px 12px;
  }

  .no-events {
    padding: 30px;
    font-size: 1rem;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .filtersSection,
  .eventsSection {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .toggleButton {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .no-events {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

/* High Contrast Mode Support */
@media (forced-colors: active) {
  .event-sub-heading::after {
    background-color: CanvasText;
  }

  .filtersSection,
  .eventsSection,
  .toggleButton {
    border: 1px solid CanvasText;
  }
}
